import React, { FC } from 'react'
import styled from 'styled-components'

type ButtonProps = {
  primary?: boolean
}

export const Button = styled.button`
  background: ${(props: ButtonProps) =>
    props.primary ? 'linear-gradient(to top, #0084d1 0%, #4233b0 50%, #a902f6 100%)' : '#000722 '};
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  padding: 16px;
  border-radius: 8px;
  max-width: '100%';
  min-width: 25vw;
  height: auto;
`
