import React from 'react'
import { useSelector } from 'react-redux'
import { Routes } from 'routes/routes'
// import { authService } from 'services/AuthService'
import { Route, Redirect } from 'react-router-dom'
import { getUser } from 'store/modules/auth/selectors'
import { FC } from 'react'
// import { createGlobalStyle, ThemeProvider } from 'styled-components'

type TProps = {
  path: Routes
}

export const PrivateRoute: FC<TProps> = ({ children, ...props }) => {
  // const { token } = authService
  // const user = useSelector((state) => state.auth)
  const user = useSelector(getUser)
  // const GlobalStyle = createGlobalStyle
  const token = Boolean(localStorage.getItem('token'))
  console.log(children)
  console.log('props')
  console.log(props)
  return (
    <>
      <Route
        {...props}
        render={({ location }) =>
          token ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: Routes.auth,
                state: { from: location },
              }}
            />
          )
        }
      />
    </>
  )
}
