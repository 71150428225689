import React, { FC } from 'react'
import { MenuContainer } from './components/menuContainer'
import { ThemeProvider } from 'styled-components'
import { mainTheme } from 'styles/main-theme'
import { BaseLayout } from 'components/baseLayout'
import { GlobalStyle } from 'styles/global-styles'

// type Props = {}

export const HomePage: FC /*<Props>*/ = () => {
  return (
    <ThemeProvider theme={mainTheme}>
      <GlobalStyle />
      <BaseLayout>
        Homepage
        <MenuContainer />
      </BaseLayout>
    </ThemeProvider>
  )
}
