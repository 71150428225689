import { request } from 'api'
import { LoginFormState } from 'pages/auth/loginPage/LoginForm'
import { AppDispatch } from 'store/store'
import { isLoaded, isLoading, setAuthSuccess } from 'store/modules/auth/reducer'

export const authRequest = (data: LoginFormState) => {
  return (dispatch: AppDispatch) => {
    dispatch(isLoading(true))
    request
      .post('/signin/email', {
        ...data,
      })
      .then(function (response) {
        const data = response.data.data
        localStorage.setItem('token', data.token)
        dispatch(isLoading(false))
        dispatch(setAuthSuccess(true))
      })
      .catch(function (err) {
        console.log('authRequest request error: ', err)
        const errStatusCode = err.response.data.statusCode
        if (errStatusCode === 1001) {
          dispatch(isLoading(false))
        }
      })
  }
}
