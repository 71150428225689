import React, { FC, useEffect } from 'react'
import { Input } from 'pages/auth/components/input'
import { ThemeProvider } from 'styled-components'
import logo from 'assets/images/logo.svg'
import { Link, useHistory } from 'react-router-dom'
import { Routes } from 'routes/routes'
import { Button } from 'pages/auth/components/button'
import { Container } from 'pages/auth/components/container'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { isLoaded, isLoading, setUserToken } from 'store/modules/auth/reducer'
import { authRequest } from 'store/modules/auth/actions'
import { getIsLoaded, getAuthSuccess, getIsLoading, getUser } from 'store/modules/auth/selectors'
import { GlobalStyle } from 'styles/global-styles'
import { styles } from 'pages/auth/styles'
// import { LinkStyles } from 'pages/auth/styles'

export type LoginFormState = {
  email: string
  password: string
  isLoading: false
}

export const LoginForm: FC = () => {
  const { register, errors, handleSubmit } = useForm<LoginFormState>()

  const dispatch = useDispatch()
  const history = useHistory()
  const userIsLogin = useSelector(getAuthSuccess)

  const onSubmit = (data: LoginFormState) => {
    dispatch(authRequest(data))
  }

  const user = useSelector(getUser)
  const loaded = useSelector(getIsLoaded)
  const loading = useSelector(getIsLoading)

  const inputHandler = () => {
    dispatch(isLoaded(false))
  }

  useEffect(() => {
    console.log('authPage useEffect')
    if (userIsLogin) {
      console.log('authorizationSuccess in authPage', userIsLogin)
      history.push(Routes.home)
    }
  }, [userIsLogin])

  return (
    <ThemeProvider theme={{ fontSize: '1.5rem' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GlobalStyle whiteColor />
        <Container>
          <div>
            <img style={styles.Logo} src={logo} alt="караоке" />
          </div>
          <div style={styles.Title}> Объединённые караоке системы </div>
          <div style={styles.SubTitle}> Авторизация </div>

          <Input
            primaryText
            name="email"
            placeholder="Email"
            type="email"
            required
            defaultValue="test@test.com"
            onInput={inputHandler}
            ref={register()}
          />
          {errors.email?.type === 'required' && <p>Обязательное поле</p>}
          {errors.email?.type === 'minLength' && <p>Введите email </p>}
          <Input
            primaryText
            name="password"
            type="password"
            placeholder="Пароль"
            required
            defaultValue="123456"
            onInput={inputHandler}
            ref={register({ minLength: 6 })}
          />
          {errors.password?.type === 'required' && <p>Обязательное поле</p>}
          {errors.password?.type === 'minLength' && <p>Неверный пароль</p>}
          <Link style={styles.Link} to={Routes.auth}>
            Восстановить пароль
          </Link>
          <Button primary disabled={false}>
            Войти
          </Button>
          <Link style={styles.Link} to={Routes.signup}>
            Или Зарегистрироваться
          </Link>
        </Container>
      </form>
    </ThemeProvider>
  )
}
