import React, { FC } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { GlobalStyle } from 'styles/global-styles'
import avatar from 'assets/icons/avatar.svg'
import { NavLink, useLocation } from 'react-router-dom'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 30vw;
`

const activeClassName = 'nav-item-active'

const Item = styled(NavLink).attrs({ activeClassName })`
  height: 56px;
  text-align: left;
  display: flex;
  flex-direction: row;
  margin: 16px;
  align-items: left;
  background: #f8f9fb;
  border-radius: 16px;
  text-decoration: none;
  color: #3e4347;
  &.${activeClassName} {
    background: linear-gradient(to right, #0084d1 0%, #4233b0 50%, #a902f6 100%);
    color: #f5f5f5;
  }
`

const Avatar = styled.div`
  max-width: 30px;
  margin: 10px;
  height: auto;
  fill: salmon;
`

const Text = styled.div`
  text-align: left;
  margin: 10px;
  font-size: 18px;
  align-items: center;
`
export type TMenuItem = {
  title: string
  icon: any
  route: string
  // onClick?: () => void;
  // badgeContent?: string;
}

type TProps = {
  item: TMenuItem
}
/* eslint-disable */

export const MenuItem: FC<TProps> = ({ item }) => {
  const location = useLocation();
  const active = location.pathname.split("/")[1] === item.route.split("/")[1];

  return (
    <ThemeProvider theme={{ fontSize: '18px' }}>
      <GlobalStyle />
      <Wrapper>
          <Item to={item.route}>
            <Avatar>
              <img src={item.icon} alt="Avatar" />
            </Avatar>
            <Text> {item.title}
              <NavLink to={item.route} />
            </Text>
          </Item>
      </Wrapper>
    </ThemeProvider>
  )
}
