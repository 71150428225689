import { createGlobalStyle, ThemeProvider } from 'styled-components'
type TextProps = {
  whiteColor?: boolean
  fontSize?: string
}
export const GlobalStyle = createGlobalStyle`

body { 
  margin: 0;
  // font-size: 1.5rem;
  font-size: ${(props) => props.theme.fontSize};
  font-family: 'Roboto', sans-serif;
  color: ${(props: TextProps) => (props.whiteColor ? '#f5f5f5' : '#3E4347')};
  background: #FFFFFF;
  overflow-y: hidden;
}
button {
    color: #f5f5f5;
    font-size: 1.5rem;
}
input {
    font-size: 1.5rem;
}
`
// export default GlobalStyle
