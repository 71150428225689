import React, { FC } from 'react'
import { LoginForm } from 'pages/auth/loginPage/LoginForm'
import styled from 'styled-components'

const Wrapper = styled.section`
  min-height: 100vh;
  background: linear-gradient(151.53deg, #330255 1.35%, #000722 46.53%);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 0;
`

export const AuthPage: FC = () => {
  return (
    <Wrapper>
      <LoginForm />
    </Wrapper>
  )
}
