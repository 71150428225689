import { request } from 'api'
import { RegistrationFormState } from 'pages/auth/registrationPage/index'
import { AppDispatch } from 'store/store'
import { isLoaded, isLoading, setRegistrationSuccess } from 'store/modules/registration/reducer'

export const registrationRequest = (data: RegistrationFormState) => {
  return (dispatch: AppDispatch) => {
    console.log('dispatch registrationRequest')
    dispatch(isLoading(true))
    request
      .post('/signup/email', data)
      .then(function (response) {
        const data = response.data.data
        localStorage.setItem('token', data.token)
        dispatch(isLoading(false))
        dispatch(setRegistrationSuccess(true))
      })
      .catch(function (err) {
        console.log('registrationRequest request error: ', err)
        const errStatusCode = err.response.data.statusCode
        if (errStatusCode === 1001) {
          dispatch(isLoading(false))
        }
      })
  }
}
