import React, { FC } from 'react'
import logo from 'assets/images/logo-min.svg'
import styled from 'styled-components'
// type TProps = {}

export const Logo = styled.div`
  background-color: #f2f2f2;
  padding: 12px;
`

export const Header: FC /*<TProps>*/ = () => {
  return (
    <Logo>
      <img src={logo} alt="Логотип ОКС" />
    </Logo>
  )
}
