import React from 'react'
import styled from 'styled-components'

export const Title = {
  textTransform: 'uppercase',
} as React.CSSProperties

const SubTitle = {
  fontStyle: 'bold',
  fontWeight: 700,
}

const Logo = {
  width: '100%',
  height: 'auto',
  maxWidth: '431px',
}

const Link = {
  textDecoration: 'none',
  color: '#F5F5F5',
  fontSize: 18,
}

// export const LinkStyles = styled.div`
//   color: ${(props) => props.theme.colors};
// `

const LogoSecondary = {
  width: '100%',
  height: 'auto',
  maxWidth: '146px',
}

const TitleSecondary = {
  fontSize: '9px',
  fontWeight: 'normal',
  textTransform: 'uppercase',
} as React.CSSProperties

export const styles = {
  Title: Title,
  TitleSecondary: TitleSecondary,
  SubTitle: SubTitle,
  Logo: Logo,
  Link: Link,
  LogoSecondary: LogoSecondary,
  // Input: Input,
}
