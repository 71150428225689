export enum Routes {
  home = '/',
  signup = '/signup',
  auth = '/auth',
  restorePassword = '/restorePassword',
  user = '/user/:id',
  users = '/users',
  players = '/players',
  catalogues = '/catalogues',
  statistics = '/statistics',
}
