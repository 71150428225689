import React from 'react'
import { MenuSidebarItem } from 'pages/home/components/menuContainer'
import styled from 'styled-components'
import { Header } from 'pages/home/components/header'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const BaseLayout: React.FC /*<TProps>*/ = () => {
  return (
    <Wrapper>
      <Header />
      <MenuSidebarItem />
    </Wrapper>
  )
}
