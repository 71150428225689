import React, { FC } from 'react'
import styled from 'styled-components'
import { MenuItem, TMenuItem } from 'pages/home/components/menuItem'
import avatar from 'assets/icons/avatar.svg'
import catalogue from 'assets/icons/catalogue.svg'
import player from 'assets/icons/player.svg'
import statistics from 'assets/icons/statistics.svg'
import users from 'assets/icons/users.svg'
import { Routes } from 'routes/routes'

// import { TMenuItem } from 'src/pages/home/components/menuItem'

// type TProps = {}
export const MenuContainer = styled.div`
  display: flex;
  width: 27vw;
  // padding: 16px;
  flex-direction: column;
`

export const MenuSidebarItem: FC = () => {
  const sidebarItems: TMenuItem[] = [
    { title: 'Пользователь', icon: avatar, route: Routes.user },
    { title: 'Пользователи', icon: player, route: Routes.users },
    { title: 'Плееры', icon: users, route: Routes.players },
    { title: 'Каталоги', icon: catalogue, route: Routes.catalogues },
    { title: 'Статистика', icon: statistics, route: Routes.statistics },
  ]
  return (
    <MenuContainer>
      {/*<MenuItem item={sidebarItems} />*/}
      {/*<MenuItem key={item}/>*/}
      {/*<MenuItem item={item.title}/>*/}
      {sidebarItems.map((item) => (
        <MenuItem key={item.title} item={item} />
      ))}
    </MenuContainer>
  )
}
