import styled from 'styled-components'

type InputProps = {
  primaryBackground?: boolean
  primaryText?: boolean
  primaryPlaceholder?: boolean
}

export const Input = styled.input`
  position: relative;
  background: ${(props: InputProps) =>
    props.primaryBackground ? 'linear-gradient(to right, #0084d1 0%, #4233b0 50%, #a902f6 100%)' : '#000722 '};
  color: ${(props: InputProps) => (props.primaryText ? '#F5F5F5' : '#3E4347')};
  max-width: 25vw;
  height: auto;
  padding: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 13px;
  text-align: center;

  &:placeholder {
    ${(props: InputProps) => (props.primaryPlaceholder ? '#3E4347' : '#F5F5F5 ')};
  }

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    background-image: linear-gradient(to right, #0084d1 0%, #4233b0 50%, #a902f6 100%);
    border-radius: 14px;
    z-index: -1;
  }
`

// background: ${(props: InputProps) => (props.primary ? 'palevioletred' : 'transparent')};
// color: ${(props: InputProps) => (props.primary ? 'white' : '#F5F5F5')};
// font-size: 1em;
// margin: 1em;
// padding: 0.25em 1em;
// border: 1px solid transparent;
// border-radius: 1px;
// border-image: linear-gradient(to right, #0084d1 0%, #4233b0 50%, #a902f6 100%);
// border-image-slice: 1;

// div {
//   position: relative;
//   width: 200px;
//   height: 60px;
//   margin: 30px;
//   border-radius: 30px;
//   background: #fff;
//   text-align: center;
// }
// div::before {
//   content: "";
//   position: absolute;
//   top: -2px;
//   bottom: -2px;
//   left: -2px;
//   right: -2px;
//   background: linear-gradient(35deg, red, green);
//   border-radius: 34px;
//   z-index: -1;
// }

// border-image: linear-gradient(to right, 0084d1#, 100%, 4233b0#, 100%, A902F6#, 100%);
// #0084D1 @ 0%
// #4233B0 @ 50%
// #A902F6 @ 100%
