import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 5vh;
  width: 60vh;
  height: auto;
  min-height: 75vh;
  position: relative;
  text-align: center;
  background: linear-gradient(151.53deg, #330255 1.35%, #000722 46.53%);
  padding: 5vh;
  border-radius: 23px;
  align-items: center;

  &: before {
    content: '';
    position: absolute;

    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    background-image: linear-gradient(to top, #0084d1 0%, #4233b0 50%, #a902f6 100%);
    border-radius: 24px;
    z-index: -1;
  }
`
