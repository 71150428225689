import React, { FC, useEffect } from 'react'
import { mainTheme } from 'styles/main-theme'
import { GlobalStyle } from 'styles/global-styles'
import { Input } from 'pages/auth/components/input'
import { isLoaded } from 'store/modules/registration/reducer'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { getRegistrationSuccess, getIsLoaded, getIsLoading, getUser } from 'store/modules/registration/selectors'
import { registrationRequest } from 'store/modules/registration/actions'
import { Button } from 'pages/auth/components/button'
import { Container } from 'pages/auth/components/container'
import styled, { DefaultTheme, ThemeProvider } from 'styled-components'
import { Routes } from 'routes/routes'
import { styles } from 'pages/auth/styles'
import logo from 'assets/images/logo.svg'

export type RegistrationFormState = {
  email: string
  password: string
  isLoading: false
}

export const RegistrationPage: FC /*<Props>*/ = () => {
  const { register, errors, handleSubmit } = useForm<RegistrationFormState>()

  const dispatch = useDispatch()
  const history = useHistory()
  const userSignedUp = useSelector(getRegistrationSuccess)

  const onSubmit = (data: RegistrationFormState) => {
    dispatch(registrationRequest(data))
  }

  const user = useSelector(getUser)
  const loaded = useSelector(getIsLoaded)
  const loading = useSelector(getIsLoading)

  const inputHandler = () => {
    dispatch(isLoaded(false))
  }

  useEffect(() => {
    if (userSignedUp) {
      history.push(Routes.auth)
    }
  }, [userSignedUp])

  const Wrapper = styled.section`
    min-height: 100vh;
    background: linear-gradient(151.53deg, #330255 1.35%, #000722 46.53%);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 0;
  `

  return (
    <ThemeProvider theme={{ fontSize: '1.5rem' }}>
      <GlobalStyle whiteColor={true} />

      <Wrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
            <div>
              <img style={styles.LogoSecondary} src={logo} alt="караоке" />
            </div>
            <div style={styles.TitleSecondary}> Объединённые караоке системы </div>
            <div style={styles.SubTitle}> Авторизация </div>
            <Input
              primaryText
              name="email"
              placeholder="Email"
              type="email"
              required
              defaultValue=""
              onChange={inputHandler}
              ref={register()}
            />
            <Input
              primaryText
              name="password"
              placeholder="Пароль"
              type="password"
              required
              defaultValue=""
              onChange={inputHandler}
              ref={register()}
            />

            {/*<Input*/}
            {/*  name="passwordCheck"*/}
            {/*  placeholder="Повторите пароль"*/}
            {/*  type="password"*/}
            {/*  required*/}
            {/*  defaultValue=""*/}
            {/*  onChange={inputHandler}*/}
            {/*  ref={register()}*/}
            {/*/>*/}
            {/*{errors.password?.type === 'required' && <p>Обязательное поле</p>}*/}
            {/*{errors.password?.type === 'minLength' && <p>Пароль должен быть длиннее 6 символов</p>}*/}

            {/*<Input*/}
            {/*  name="organisationName"*/}
            {/*  placeholder="Название организации"*/}
            {/*  type="string"*/}
            {/*  required={false}*/}
            {/*  defaultValue=""*/}
            {/*  onChange={inputHandler}*/}
            {/*  ref={register()}*/}
            {/*/>*/}
            {/*<Input*/}
            {/*  name="itn"*/}
            {/*  placeholder="ИНН"*/}
            {/*  type="string"*/}
            {/*  required={false}*/}
            {/*  defaultValue=""*/}
            {/*  onChange={inputHandler}*/}
            {/*  ref={register()}*/}
            {/*/>*/}
            {/*<Input*/}
            {/*  name="name"*/}
            {/*  placeholder="Имя"*/}
            {/*  type="string"*/}
            {/*  required={false}*/}
            {/*  defaultValue=""*/}
            {/*  onChange={inputHandler}*/}
            {/*  ref={register()}*/}
            {/*/>*/}
            {/*<Input*/}
            {/*  name="surname"*/}
            {/*  placeholder="Фамилия"*/}
            {/*  type="string"*/}
            {/*  required={false}*/}
            {/*  defaultValue=""*/}
            {/*  onChange={inputHandler}*/}
            {/*  ref={register()}*/}
            {/*/>*/}
            {/*<Input*/}
            {/*  name="number"*/}
            {/*  placeholder="Номер телефона"*/}
            {/*  type="string"*/}
            {/*  required={false}*/}
            {/*  defaultValue=""*/}
            {/*  onChange={inputHandler}*/}
            {/*  ref={register()}*/}
            {/*/>*/}
            <Button primary type="submit">
              Войти
            </Button>
          </Container>
        </form>
      </Wrapper>
    </ThemeProvider>
  )
}
